.landing,
.feature {
    @apply text-base leading-relaxed font-brand-default;
}

.landing h1,
.feature h1 {
    @apply text-4xl leading-[2.75rem] font-brand-bold;
}
.landing h2,
.feature h2 {
    @apply text-3xl leading-9 font-brand-bold;
}
.landing h3,
.feature h3 {
    @apply text-2xl leading-8 font-brand-bold;
}
.landing h4,
.feature h4 {
    @apply text-xl leading-7 font-brand-bold;
}

.landing p,
.landing ul,
.landing ol,
.landing blockquote,
.feature p,
.feature ul,
.feature ol,
.feature blockquote {
    @apply my-6 list-inside;
}

.feature a {
    @apply text-brand-blue-nu;
}

.feature p {
    @apply text-sm;
}
